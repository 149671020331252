import { filterObjectByFilledValues } from '@st/utils'

// eslint-disable-next-line
type TelegramAuthParams = {
  language: any // 'en' | 'ru' | 'es' | 'pt'
  referralCode?: string
  promocodeName?: string
}

export function useTelegramAuthWidget() {
  const config = useRuntimeConfig()
  const botId = config.public.telegramAuthBotId as string
  const stFetch = useRawStFetch()

  async function handleTelegramAuth(params: TelegramAuthParams) {
    const filteredParams = filterObjectByFilledValues(params)
    const telegramLogin = globalThis.Telegram.Login

    return new Promise((resolve, reject) => {
      if (!botId) {
        console.error('NUXT_PUBLIC_TELEGRAM_AUTH_BOT_ID is missing')
        reject()
        return
      }

      /*
       вместо встраивания инлайн скрипта с iframe tg кнопкой
       вызывается auth методо из ранее загруженного скрипта
       принимающий botId и язык
       в колбэке возвращаются данные
      */
      const { language } = filteredParams
      telegramLogin.auth(
        {
          bot_id: botId,
          request_access: 'write',
          lang: language ?? 'en',
        },
        async (tgData) => {
          if (!tgData) {
            console.error('Telegram authorization failed')
            reject()
            return
          }

          await stFetch('/auth/telegram', {
            method: 'post',
            body: {
              ...filteredParams,
              tgData,
            },
          })

          resolve(tgData)
        },
      )
    })
  }

  return {
    handleTelegramAuth,
  }
}
