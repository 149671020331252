/**
 * Iterates over elements of collection,
 * returning a new object of elements
 * For objects: filter({ a: 1, b: 0 }, (item) => item > 0) returns { a: 1 }
 */
type FilterCallback<T> = (
  value: T[keyof T],
  key: keyof T,
  object: Record<keyof T, T[keyof T]>,
) => boolean

export function filterObject<T extends Record<string, unknown>>(
  object: T,
  filterCallback: FilterCallback<T>,
) {
  const filteredObject = {} as Record<keyof T, T[keyof T]>
  for (const key in object) {
    const result = filterCallback(object[key], key, object)
    if (result) {
      filteredObject[key] = object[key]
    }
  }

  return filteredObject
}

export function filterObjectByFilledValues<T extends Record<string, unknown>>(
  object: T,
) {
  return filterObject(object, (value: unknown) => {
    return (
      value === 0 ||
      !!(
        (value && typeof value !== 'object') ||
        (Array.isArray(value) && value[0])
      )
    )
  })
}

export function filterObjectByFilledValuesWithZeros<
  T extends Record<string, unknown>,
>(object: T) {
  return filterObject(
    object,
    (value: unknown) =>
      value !== null &&
      value !== undefined &&
      (!Array.isArray(value) || !!value[0]),
  )
}
